var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'two-factor-authentication': true,
    'two-factor-authentication-profile': _vm.isProfile
  }},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.OTPValue),expression:"OTPValue"},{name:"focus",rawName:"v-focus"}],staticClass:"input is-medium password",attrs:{"type":"text","placeholder":_vm.placeholderInputText},domProps:{"value":(_vm.OTPValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.OTPValue=$event.target.value},_vm.updatePayload],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validate.apply(null, arguments)}}}),_c('span',{staticClass:"icon"},[(_vm.chosenTwoFA === 'totp')?_c('smartphone-icon',{attrs:{"width":"20","height":"20"}}):(_vm.chosenTwoFA === 'email_otp')?_c('mail-icon',{attrs:{"width":"20","height":"20"}}):(_vm.chosenTwoFA === 'recovery_code')?_c('key-icon',{attrs:{"width":"20","height":"20"}}):_vm._e()],1)]),_c('button-simple',{class:{
          button: true,
          'main-button': true,
          'is-fullwidth': true,
          'is-loading': _vm.isLoading,
          'disable-button': _vm.isDisableButton
        },attrs:{"text":_vm.textValidateButtonOrVerify},on:{"click":_vm.validate}}),(_vm.isWrongOtp)?_c('p',{staticClass:"control error"},[_vm._v(" "+_vm._s(_vm.wrongOTPError)+" ")]):_vm._e(),(_vm.chosenTwoFA === 'email_otp')?_c('p',[_c('a',{on:{"click":_vm.requestSendEmailOTP}},[_vm._v(" "+_vm._s(_vm.$t('login.send_email_otp'))+" ")])]):_vm._e(),_c('p',{staticClass:"control"},[_vm._v(" "+_vm._s(_vm.informationTwoFA)+" ")]),((_vm.othersTwoFA.length >= 1))?_c('div',[_c('p',{staticClass:"control"},[_vm._v(" "+_vm._s(_vm.unableToVerify)+" ")]),_c('ul',_vm._l((_vm.othersTwoFA),function(twoFA){return _c('li',{key:twoFA},[_c('a',{on:{"click":function($event){return _vm.changeTwoFA(twoFA)}}},[_vm._v(" "+_vm._s(_vm.changeTwoFAText(twoFA))+" ")])])}),0)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }